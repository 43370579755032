<template>
    <div class="hold-transition">
      <div class="row">
        <div class="col-md-12">
          <table
            class="
              table table-bordered table-striped table-hover table-sm
              text-xs
            "
          >
            <thead class="bg-gray text-center">
              <tr>
                <!-- <th class="text-nowrap">
                  Acciones
                  <button
                    type="button"
                    class="btn btn-sm bg-primary text-white"
                    @click="addRuta()"
                    :disabled="
                      estado_ruta == 1 ||
                      !$parent.form.id ||
                      $parent.form.estado == 2
                    "
                    style="cursor: pointer"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th> -->
                <th class="text-center">Tipo Sitio</th>
                <th class="text-center">Sitio</th>
                <th class="text-center">Dirección</th>
                <th class="text-center">Contacto</th>
                <th class="text-center">Número de Contacto</th>
                <th class="text-center">Correo</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr v-for="(rut, index) in rutas" :key="rut.id">
                <!-- <td class="text-center">
                  <div class="btn-group" v-if="estado_ruta != 2">
                    <button
                      type="button"
                      class="btn bg-navy"
                      :disabled="estado_ruta != -1"
                      @click="editRuta(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      :disabled="estado_ruta != -1"
                      @click="destroyRuta(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="estado_ruta == 2 && id_actualizar_ruta == index"
                  >
                    <button
                      type="button"
                      class="btn bg-success"
                      v-show="!$v.ruta.$invalid"
                      @click="saveUpdateRuta(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelRuta()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="estado_ruta == 2 && id_actualizar_ruta !== index"
                  >
                    <button
                      type="button"
                      class="btn bg-navy"
                      disabled
                      @click="editRuta(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      disabled
                      @click="destroyRuta(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td class="text-center">
                  <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                    <select
                      placeholder="Tipo sitio"
                      label="nombres"
                      class="form-control form-control-sm p-0"
                      v-model="ruta.tipo_sitio"
                      :class="
                        $v.ruta.tipo_sitio.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option value="1">Origen</option>
                      <option value="2">Destino</option>
                    </select>
                  </div>
                  <div v-else>
                    <span
                      class="badge"
                      :class="rut.tipo_sitio == 1 ? 'bg-success' : 'bg-primary'"
                    >
                      {{ rut.tipo_sitio == 1 ? "Origen" : "Destino" }}
                    </span>
                  </div>
                </td>
                <td class="text-center">
                  <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre"
                      v-model="ruta.nombre"
                      :class="$v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <div v-else>{{ rut.nombre }}</div>
                </td>
                <td class="text-center">
                  <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="direccion"
                      v-model="ruta.direccion"
                      :class="
                        $v.ruta.direccion.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ rut.direccion }}</div>
                </td>
                <td class="text-center">
                  <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre_contacto"
                      v-model="ruta.nombre_contacto"
                      :class="
                        $v.ruta.nombre_contacto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ rut.nombre_contacto }}</div>
                </td>
                <td class="text-center">
                  <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="telefono_contacto"
                      v-model="ruta.telefono_contacto"
                      :class="
                        $v.ruta.telefono_contacto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ rut.telefono_contacto }}</div>
                </td>
                <td class="text-center">
                  <div
                    class="input-group mb-3"
                    v-if="id_actualizar_ruta === index && estado_ruta === 2"
                  >
                    <input
                      type="email"
                      class="form-control form-control-sm"
                      v-model="ruta.email_contacto"
                      id="email_contacto"
                      :class="
                        $v.ruta.email_contacto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      placeholder="Email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
  
                  <div v-else>{{ rut.email_contacto }}</div>
                </td>
              </tr>
              <tr v-if="estado_ruta === 1">
                <!-- <td>
                  <div class="btn-group float-right">
                    <button
                      class="btn bg-success"
                      v-show="!$v.ruta.$invalid"
                      @click="saveRuta()"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelRuta()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td>
                  <select
                    placeholder="Tipo sitio"
                    label="nombres"
                    class="form-control form-control-sm p-0"
                    v-model="ruta.tipo_sitio"
                    :class="
                      $v.ruta.tipo_sitio.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option value="1">Origen</option>
                    <option value="2">Destino</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nombre"
                    v-model="ruta.nombre"
                    :class="$v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="direccion"
                    v-model="ruta.direccion"
                    :class="
                      $v.ruta.direccion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nombre_contacto"
                    v-model="ruta.nombre_contacto"
                    :class="
                      $v.ruta.nombre_contacto.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="telefono_contacto"
                    v-model="ruta.telefono_contacto"
                    :class="
                      $v.ruta.telefono_contacto.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="email_contacto"
                    v-model="ruta.email_contacto"
                    :class="
                      $v.ruta.email_contacto.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 text-center">
          <h6 v-if="!(ruta_descargue == 1 && ruta_cargue == 1)">
            <span class="badge badge-warning"
              ><i class="fas fa-times-circle"></i> Recuerde que se debe agregar
              sitio de origen y destino</span
            >
          </h6>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {
    required,
    email,
    minLength,
    maxLength,
  } from "vuelidate/lib/validators";
  import axios from "axios";
  
  export default {
    name: "CsAsignacionesRutas",
    components: {},
    data() {
      return {
        rutas: [],
        ruta: {
          id: null,
          cs_solicitud_nacional_id: null,
          tipo_sitio: null,
          nombre: null,
          direccion: null,
          nombre_contacto: null,
          telefono_contacto: null,
          email_contacto: null,
        },
        estado_ruta: -1,
        ruta_cargue: 0,
        ruta_descargue: 0,
        id_actualizar_ruta: -1,
      };
    },
    validations: {
      ruta: {
        tipo_sitio: {
          required,
        },
        nombre: {
          required,
        },
        direccion: {
          required,
        },
        nombre_contacto: {
          required,
        },
        telefono_contacto: {
          required,
          maxLength: maxLength(10),
          minLength: minLength(10),
        },
        email_contacto: {
          required,
          email,
        },
      },
    },
    methods: {
      async getRutas() {
        this.cargando = true;
        await axios
          .get("/api/funcionariosFrontera/solicitudesNacionalesRuta", {
            params: { solicitudNacional_id: this.$parent.form.id },
          })
          .then(async (response) => {
            this.rutas = response.data;
            await this.calcularRutas();
            this.cargando = false;
          });
      },
  
      async calcularRutas() {
        this.ruta_cargue = 0;
        this.ruta_descargue = 0;
        this.rutas.forEach((element) => {
          if (element.tipo_sitio == 1) {
            this.ruta_cargue++;
          }
          if (element.tipo_sitio == 2) {
            this.ruta_descargue++;
          }
        });
      },
  
      async addRuta() {
        await this.limpiarRuta();
        this.ruta.cs_solicitud_nacional_id = this.$parent.form.id;
        this.estado_ruta = 1;
      },
  
      async limpiarRuta() {
        this.ruta = {
          id: null,
          cs_solicitud_nacional_id: null,
          tipo_sitio: null,
          nombre: null,
          direccion: null,
          nombre_contacto: null,
          telefono_contacto: null,
          email_contacto: null,
        };
      },
  
      async editRuta(index) {
        this.id_actualizar_ruta = index;
        this.estado_ruta = 2;
        this.ruta = { ...this.rutas[index] };
      },
  
      async cancelRuta() {
        this.estado_ruta = -1;
      },
  
      async saveRuta() {
        this.rutas.push(this.ruta);
        await this.calcularRutas();
        this.estado_ruta = -1;
      },
  
      async saveUpdateRuta(index) {
        this.rutas[index] = this.ruta;
        this.estado_ruta = -1;
        this.id_actualizar_ruta;
        await this.calcularRutas();
      },
  
      async saveRutaTotal() {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/funcionariosFrontera/solicitudesNacionalesRuta",
          data: {
            cs_solicitud_nacional_id: this.$parent.form.id,
            rutas: this.rutas,
          },
        })
          .then(async (response) => {
            this.cargando = false;
            await this.getRutas();
            this.guardar++;
          })
          .catch((e) => {
            this.message += e.message;
          });
      },
  
      async destroyRuta(index) {
        await this.rutas.splice(index, 1);
        await this.calcularRutas();
      },
    },
  };
  </script>